:root {
	--color1: #5d676d;
	--color2: #0095d8;
	--color3: #262626;
}

@font-face {
	font-family: "h55";
	    src: url('/fonts/helvetica55.ttf') format('truetype');
	font-weight: normal !important;
	font-style: normal;
}

@font-face {
	font-family: "h67";
	    src: url('/fonts/helvetica67.ttf') format('truetype');
	font-weight: normal !important;
	font-style: normal;
}

@font-face {
	font-family: "h67m";
	    src: url('/fonts/helvetica67m.ttf') format('truetype');
	font-weight: normal !important;
	font-style: normal;
}

@font-face {
	font-family: "h75";
	    src: url('/fonts/helvetica75_bold.ttf') format('truetype');
	font-weight: normal !important;
	font-style: normal;
}

body {
	background: #f9f9f9;
}

.header {
	padding: 5px 0;
	text-align: right;
    background: var(--color1);
    
    svg {
	    background: #bac4ca;
	    width: 30px;
	    height: 30px;
	    border-radius: 50%;
	    padding: 6px;
	    margin-right: 10px;
	    border: 3px solid #88969f;
    }
}

.App-logo {
    width: 125px;
    height: 50px;
}

h1, .h1 {
    color: var(--color2) !important;
    font: normal 45px "h55";
    text-transform: uppercase;
}

h2, .h2 {
    color: var(--color3);
    font-size: 26px;
    letter-spacing: -0.65px;
    font-family: "h75";
    text-transform: uppercase;
}

h3, .h3 {
    color: var(--color3);
    font-size: 21px;
    letter-spacing: -0.65px;
    font-family: "h67m";
}

h4, .h4 {
    color: var(--color1);
    font-size: 24px;
    font-family: "h67m";
    text-transform: uppercase;
}

.subtitle {
    color: var(--color1);
    font-size: 18px;
    font-family: "h67m";
    text-transform: uppercase;
}

.text {
	p {
    color: var(--color3);
    padding-left: 20px;
    margin: 0 0 12px 0;
    border-left: 1px solid var(--color2);
	} 
}

.text2 {
    color: var(--color1);
    font-size: 13px;
    font-family: "h55";	
}

.blue {
	color: var(--color2);
}

.menu_top {
	border-bottom: 1px solid var(--color1);
	margin-bottom: 50px;
	background: #fff;

	.menu_top_nav {
		margin-right: 0;
		margin-left: auto;
	}

	a {
	    display: block;
	    font-size: 18px;
	    color: var(--color1);
	    font-weight: normal;
	    font-family: "h67";
	    text-transform: uppercase;
	    padding: 12px 8px 2px;
	    text-decoration: none;
	    position: relative;
	}

	a:hover, a.active {
		color: var(--color2);	
	}

	a span {
	width: 100%;
	height: 2px;
	background: var(--color2);
	display: block;
	position: absolute;
	opacity: 0;
	left: 0;
	bottom: -3px;;
	}

	a.active span {
	opacity: 1;	
	}
}

.grey_block {
	padding: 90px 0;
	background: #e7e7e7;
}

.mBottom {
	margin-bottom: 90px;	
	overflow: hidden;
}

.carousel-item {
	text-align: center;
}

footer {
color: #fff;
padding: 50px 0;
border-top: 1px solid #3f464a;	
background: #565e64;
}

.bottom_menu {
	list-style: none;
	font-size: 13px;

}